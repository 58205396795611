* {
  box-sizing: border-box;
}

.App {
  font-family: sans-serif;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  margin: 2rem 0;
}

.title {
  text-align: center;
}

.title > h1,h2 {
  margin: 0.2rem 0;
}

.links-list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 48rem;
}

.link-card {
  margin: 0.5rem 0;
  padding: 0;
  text-align: center;
}

.link-card__text {
  margin: 0;
}

.link-card__text > a {
  text-decoration: none;
  color: black;
  height: 100%;
  width: 100%;
  display: block;
  padding: 0.5rem 1rem;
  margin: 0;
}

.badges-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.badge > img {
  max-width: 12rem;
}

.card {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
}

.card:hover,
.card:active {
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.26);
}